import decode from "jwt-claims";
import { navigate } from "gatsby";
const API = process.env.GATSBY_API_URL;

/**
 * isDomAvailable
 * @description Checks to see if the DOM is available by checking the existence of the window and document
 * @see https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/core/ExecutionEnvironment.js#L12
 */
export function isDomAvailable() {
  return (
    typeof window !== "undefined" &&
    !!window.document &&
    !!window.document.createElement
  );
}

export function refreshToken(callback) {
  if (window.ACCOUNT_JWT && window.ACCOUNT_JWT_REFRESH) {
    const decoded_jwt = decode(window.ACCOUNT_JWT);
    const startTime = new Date().getTime();
    const expTime = decoded_jwt["exp"] * 1000;

    if (startTime > expTime - 1000) {
      postData(
        API + "/auth/token/refresh/",
        {
          refresh: window.ACCOUNT_JWT_REFRESH,
        },
        (error, response) => {
          if (!error) {
            window.ACCOUNT_JWT = response.access;
            callback();
          } else {
            navigate("/account/logout?force=1");
          }
        },
        false
      );
      return true;
    }
  }
  return false;
}

/**
 * getJSON
 * @description Download json by GET request, parse and return the object
 */
export function getJSON(url, callback) {
  if (refreshToken(() => getJSON(url, callback))) {
    return;
  }

  const xhr = new window.XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.setRequestHeader("Accept", "application/json");

  if (window.ACCOUNT_JWT) {
    xhr.setRequestHeader("Authorization", "Bearer " + window.ACCOUNT_JWT);
  }

  xhr.onerror = function (e) {
    callback(e);
  };
  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300 && xhr.response) {
      let data;
      try {
        data = JSON.parse(xhr.response);
      } catch (err) {
        return callback(err);
      }
      callback(null, data);
    } else {
      callback(new Error(xhr.statusText));
    }
  };
  xhr.send();
  return xhr;
}

/**
 * postJSON
 * @description Download json by GET request, parse and return the object
 */
export function postJSON(url, data, callback, refresh = true, method = "POST") {
  if (refresh) {
    if (refreshToken(() => postJSON(url, data, callback, false, method))) {
      return;
    }
  }

  const xhr = new window.XMLHttpRequest();
  xhr.open(method, url, true);
  xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  xhr.setRequestHeader("Accept", "application/json");

  if (window.ACCOUNT_JWT) {
    xhr.setRequestHeader("Authorization", "Bearer " + window.ACCOUNT_JWT);
  }

  xhr.onerror = function (e) {
    callback(e);
  };
  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300 && xhr.response) {
      let data;
      try {
        data = JSON.parse(xhr.response);
      } catch (err) {
        return callback(err);
      }
      callback(null, data);
    } else {
      callback(new Error(xhr.statusText));
    }
  };
  xhr.send(JSON.stringify(data));
  return xhr;
}

/**
 * send requests via XHR
 * @param {any} url - request endpoint
 * @param {any} data - request body
 * @param {any} callback - function(error, response) - callback runs on request complete
 * @param {any} [refresh=true] - request refresh token
 * @param {any} [method="POST"] - http method, POST, PUT, etc.
 * @returns {any}
 */
export function postData(url, data, callback, refresh = true, method = "POST") {
  if (refresh) {
    if (refreshToken(() => postData(url, data, callback, false, method))) {
      return;
    }
  }

  var form = new FormData();
  for (const [key, value] of Object.entries(data)) {
    form.append(key, value);
  }

  var xhr = new XMLHttpRequest();
  xhr.open(method, url, true);
  // xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  // xhr.setRequestHeader("Content-Type", "multipart/form-data");
  xhr.setRequestHeader("Accept", "application/json");

  if (window.ACCOUNT_JWT) {
    xhr.setRequestHeader("Authorization", "Bearer " + window.ACCOUNT_JWT);
  }

  xhr.onload = function () {
    let data;
    try {
      data = JSON.parse(xhr.response);
    } catch (err) {
      return callback(err);
    }

    if (xhr.status >= 200 && xhr.status < 300 && xhr.response) {
      callback(null, data);
    } else {
      callback(new Error(xhr.statusText), data);
    }
  };
  xhr.send(form);
}

/**
 * sends PUT request
 * @date 2021-09-09
 * @param {any} url - api endpoint
 * @param {any} data - request body
 * @param {any} callback - function(error, response) - called on request complete
 * @param {any} refresh=true - fetch refresh token
 * @returns {any}
 */
export function putData(url, data, callback, refresh = true) {
  return postData(url, data, callback, refresh, "PUT");
}

/**
 * mergeToggles
 * @description merged an array of items with TOGGLING array of items such that
 * action can be one of: [on, off, replace]
 * if action is specified and is:
 *  off - items are subtracted
 *  on - items are added
 *  replace - items are completely replaced
 * if action is a string "replace", function doesn't do any comparison
 * if action not specified, new items are merged, old items are removed
 */

export function mergeToggles(current, payload, action = null) {
  var super_items = payload.filter((x) => current.indexOf(x) === -1);
  var inter_items = payload.filter((x) => current.indexOf(x) > -1);
  // console.log(current, super_items, inter_items);

  var items = [];

  switch (action) {
    case "replace":
      return payload;
    case "on":
      // Just add
      items = current.concat(super_items);
      break;
    case "off":
      // Just subtract
      items = current.filter((x) => inter_items.indexOf(x) === -1);
      break;
    default:
      // Add and subtract (xor)
      items = current.concat(super_items);
      items = items.filter((x) => inter_items.indexOf(x) === -1);
  }

  return items;
}

/**
 * decodes account information from jwt
 * @date 2021-09-09
 * @param {any} [account_jwt] - jwt token
 * @returns {Object}
 */
export function getAccountFromToken(account_jwt) {
  const decoded_jwt = account_jwt ? decode(account_jwt) : null;

  const currentUserId =
    decoded_jwt === null || decoded_jwt === undefined
      ? undefined
      : decoded_jwt.user_id;

  return {
    user_id: currentUserId,
  };
}

/**
 * downloads data as csv
 * @param {Array} [headers=[]] - header row
 * @param {Array} [data=[]] - data to be formatted as csv
 * @param {string} [fileName="exportDataToCsv.csv"] - sets the file name to be downloaded, should include file extension if applicable
 * @param {string} [fileType="text/csv"] - sets the file type, defaults to "text/csv"
 * @param {string} [footerText=""] - optional footer text to append as the last row
 */
export const exportDataToCsv = ({
  headers = [],
  data = [],
  fileName = "exportDataToCsv.csv",
  fileType = "text/csv",
  footerText = "",
}) => {
  const deconstructedData = [headers.join(",")];

  data.forEach((row) => {
    deconstructedData.push(row.join(","));
  });

  if (footerText) {
    deconstructedData.push(`"${footerText}""`);
  }

  const deconstructedGraphData = [...deconstructedData].join("\n");
  const dataBlob = new Blob([deconstructedGraphData], {
    type: fileType,
  });

  // create a dom element to trigger the download
  const hiddenLink = document.createElement("a");
  hiddenLink.download = fileName;
  hiddenLink.href = window.URL.createObjectURL(dataBlob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  hiddenLink.dispatchEvent(clickEvt);

  // cleanup the dom element when finished
  hiddenLink.remove();
};
