// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-change-email-js": () => import("./../../../src/pages/account/change-email.js" /* webpackChunkName: "component---src-pages-account-change-email-js" */),
  "component---src-pages-account-change-name-js": () => import("./../../../src/pages/account/change-name.js" /* webpackChunkName: "component---src-pages-account-change-name-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-login-js": () => import("./../../../src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-account-logout-js": () => import("./../../../src/pages/account/logout.js" /* webpackChunkName: "component---src-pages-account-logout-js" */),
  "component---src-pages-account-register-js": () => import("./../../../src/pages/account/register.js" /* webpackChunkName: "component---src-pages-account-register-js" */),
  "component---src-pages-account-reset-password-js": () => import("./../../../src/pages/account/reset-password.js" /* webpackChunkName: "component---src-pages-account-reset-password-js" */),
  "component---src-pages-account-verify-email-js": () => import("./../../../src/pages/account/verify-email.js" /* webpackChunkName: "component---src-pages-account-verify-email-js" */),
  "component---src-pages-account-verify-user-js": () => import("./../../../src/pages/account/verify-user.js" /* webpackChunkName: "component---src-pages-account-verify-user-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

