export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE || "GeoGuard";
export const SITE_DESCRIPTION =
  process.env.REACT_APP_SITE_DESCRIPTION || "GeoGuard";
export const WARNING_TITLE = "WARNING - ";
export const WARNING_TEXT =
  "You are assessing technical data whose export is restricted by the Arms Export Control Act (Title 22, U.S.C., Sec 2751, et seq.) or the Export Administration Act of 1979, as amended (Title 50, U.S.C., App. 2401 et seq.). Violations of these export laws are subject to severe criminal penalties.";

export const COMPOSITE = "composite";
export const INDICATORS = "Indicators";
export const OVERLAYS = "Overlays";

export const DEFAULT_OVERLAY_OPACITY = 40;

export const SelectionMode = Object.freeze({
  Default: "Default Mode",
  MultiRegions: "Multi Regions",
  MultiIndicators: "Multi Indicators",
});
